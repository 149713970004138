import { combineReducers } from '@reduxjs/toolkit';
import navbarReducer from '@/redux/slices/navbarSlice';
import { culinaryApi } from '@/redux/slices/culinaryApi';
import { csrfTokenSlice } from '@/redux/slices/csrfTokenSlice';
import { productApi } from '@/redux/slices/productApi';
import { productAndDishApi } from '@/redux/slices/productAndDishApi';
import { userSettingsApi } from '@/services/userSettingsApi';
import { usersApi } from '@/services/usersApi';
import userFilterReducer from '@/redux/reducers/userFilterReducer';
import dishInCacheReducer from '@/redux/reducers/dishInCacheReducer';

const rootReducer = combineReducers({
  navbar: navbarReducer,
  userFilter: userFilterReducer,
  dishInCache: dishInCacheReducer,
  csrfToken: csrfTokenSlice.reducer,
  [culinaryApi.reducerPath]: culinaryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [productAndDishApi.reducerPath]: productAndDishApi.reducer,
  [userSettingsApi.reducerPath]: userSettingsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;